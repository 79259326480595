import { defineStore } from 'pinia';

export const userMenuStore = defineStore('usersMenu', {
  state: () => ({
    menu: JSON.parse(localStorage.getItem('usersMenu')) || []
  }),
  getters: {
    getMenu(state) {
      return state.menu;
    }
  },
  actions: {
    setUsersMenu(menu) {
      this.menu = menu;
      localStorage.setItem('usersMenu', JSON.stringify(this.menu));
    },
    clearMenu() {
      this.menu = null;
      localStorage.removeItem('usersMenu');
    }
  },
});
