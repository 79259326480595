<script setup>
import axiosInstance from "@/api/axiosInstance";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";
import { userMenuStore } from "@/stores/menu";
import {computed, onMounted, onUnmounted} from "vue";
import { useI18n } from "vue-i18n";

// Get the auth store instance
const authStore = useAuthStore();
const usersMenuStore = userMenuStore();
const usersMenu = computed(() => usersMenuStore.getMenu);

// i18n instance to change the language
const { locale, t } = useI18n();

// Use computed properties to automatically update when the store's state changes
const isAuthenticated = computed(() => authStore.isUserAuthenticated);
const isAdmin = computed(() => authStore.isAdmin);
const isLawUser = computed(() => authStore.isLawUser);
const isTradeUser = computed(() => authStore.isTradeUser);

console.log("isAdmin", isAdmin.value);
console.log("isLawUser", isLawUser.value);
console.log("isTradeUser", isTradeUser.value);

// Function to check if the user is authenticated and update the store state
function checkAuthenticationStateAndUpdateStore() {
  if (!localStorage.getItem("access_token") && !localStorage.getItem("refresh_token")) {
    authStore.logout();
    router.push("/login");
  } else if (!authStore.isUserAuthenticated) {
    const accessToken = localStorage.getItem("access_token");
    const decodedToken = JSON.parse(atob(accessToken.split(".")[1]));
    const userRole = decodedToken;

    authStore.login(userRole);

    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    router.push("/");
  }
}

onMounted(() => {
  window.addEventListener("storage", checkAuthenticationStateAndUpdateStore);
});

onUnmounted(() => {
  window.removeEventListener("storage", checkAuthenticationStateAndUpdateStore);
});

const logout = async () => {
  try {
    await axiosInstance.post("auth/logout");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    authStore.logout();
    usersMenuStore.clearMenu();
    window.dispatchEvent(new Event("localStorage"));
    await router.push("/login");
  } catch (error) {
    console.error(error);
  }
};

// Function to change the locale
const changeLocale = () => {
  locale.value = locale.value == 'en' ? 'tr' : 'en';
};

</script>

<template>
  <!-- Start: Navbar Centered Links -->
  <nav
      class="navbar navbar navbar-expand-md fixed-top navbar-shrink bg-light border-bottom border-light"
      id="mainNav"
  >
    <div class="container-fluid p-0 m-0">
      <div v-if="logoLight">
        <router-link class="logo" to="/">
          <span class="logo-light-mode">
<!--            <img src="@/assets/images/logo-dark.png" class="l-dark" alt="Logo Dark" />
            <img src="@/assets/images/logo-light.png" class="l-light" alt="Logo Light" />-->
          </span>
<!--          <img src="@/assets/images/aisistan.png" class="logo-dark-mode" alt="Logo Dark Mode" />-->
        </router-link>
      </div>

      <div class="px-3 m-3" v-else>
        <router-link class="text-xl-center text-dark" to="/">
         A(i)SISTAN
        </router-link>
      </div>

      <button data-bs-toggle="collapse" class="navbar-toggler" data-bs-target="#navcol-1">
        <span class="visually-hidden">{{ t('toggleNavigation') }}</span>
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navcol-1">
        <ul v-if="usersMenu" class="navbar-nav mx-auto">
          <li v-for="item in usersMenu" :key="item" class="nav-item mx-1 text-lg-center">
            <router-link class="btn btn-outline-secondary" :to="item.path">{{ t(item.key) }}</router-link>
          </li>
        </ul>
        <!-- Locale Switcher -->
        <div class="ms-5 me-4 text-sm-center">
          <button
              class="btn btn-sm btn-outline-dark"
              @click="changeLocale()"
              :class="{ 'active': locale === 'en' }"
          >
            EN / TR
          </button>
        </div>
        <div class="me-4 text-center text-md-end">
          <div class="d-grid">
            <router-link v-if="!isAuthenticated" class="btn btn-outline-dark" to="/login">
              {{ t('login') }}
            </router-link>
          </div>
        </div>

        <div class="me-4 text-center text-md-end">
          <div class="d-grid">
            <router-link v-if="!isAuthenticated" class="btn btn-outline-dark" to="/signup">
              {{ t('signup') }}
            </router-link>
          </div>
        </div>

        <div class="ms-4 text-center text-md-end">
          <div class="d-grid">
            <router-link
                v-if="isAuthenticated"
                class="btn btn-outline-dark"
                @click="logout"
                to="/"
            >
              {{ t('logout') }}
            </router-link>
          </div>
        </div>


      </div>
    </div>
  </nav>
  <!-- End: Navbar Centered Links -->
</template>

<script>
import { defineProps } from 'vue';

defineProps({
  logoLight: {
    type: Boolean,
    required: true,
  },
  buttonLight: {
    type: Boolean,
    required: true,
  },
  navLight: {
    type: String,
    required: true,
  },
});
</script>

<style scoped>
.locale-switcher button.active {
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>
