import {adminGuard, authenticatedGuard, redirectIfAuthenticated, userGuard} from "@/router/guards";

const routes = [
    {
        path: "/",
        name: "landing",
        component: () => import('@/pages/index/index.vue'), // Lazy loaded
    },
    {
        path: "/signup",
        name: "signup",
        component: () => import('@/views/RegisterScreen.vue'), // Lazy loaded
        beforeEnter: redirectIfAuthenticated,
    },
    {
        path: "/login",
        name: "login",
        component: () => import('@/views/LoginScreen.vue'), // Lazy loaded
        beforeEnter: redirectIfAuthenticated,
    },
    {
        path: "/forgotten-password",
        name: "forgotten-password",
        component: () => import('@/views/ForgottenPasswordView.vue'), // Lazy loaded
        beforeEnter: redirectIfAuthenticated,
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import('@/views/ResetPasswordPage.vue'), // Lazy loaded
        beforeEnter: redirectIfAuthenticated,
    },
    {
        path: "/chat/:workspaceId",
        name: "ChatScreen",
        component: () => import('@/views/chatscreen/ChatScreen.vue'), // Lazy loaded
        props: true, // Automatically passes `workspaceId` as a prop
    },
    {
        path: "/workspaces",
        name: "workspaces",
        component: () => import('@/views/workspaces/WorkspaceSelectionView.vue'), // Lazy loaded
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/assistants",
        name: "assistants",
        component: () => import('@/views/asistant/AssistantsView.vue'), // Lazy loaded
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/prompts",
        name: "prompts",
        component: () => import('@/views/prompts/PromptsView.vue'), // Lazy loaded
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/searchCase",
        name: "searchCase",
        component: () => import('@/views/searchCase/SearchCase.vue'), // Lazy loaded
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/displayCaseDetails",
        name: "displayCaseDetails",
        component: () => import('@/views/caseDisplay/DisplayCaseDetails.vue'), // Lazy loaded
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/tradeAgent",
        name: "tradeAgent",
        component: () => import('@/views/agentScreen/AgentScreen.vue'), // Lazy loaded
        props: () => ({agentType: 'funds_management'}), // Pass query params as props
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/lawAgent/:conversationId?",
        name: "lawAgent",
        component: () => import('@/views/agentScreen/AgentScreen.vue'), // Lazy loaded
        props: () => ({agentType: 'legal'}), // Pass query params as props
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/productReview/:conversationId?",
        name: "productReview",
        component: () => import('@/views/agentScreen/AgentScreen.vue'), // Lazy loaded
        props: () => ({agentType: 'product_review'}), // Pass query params as props
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/reviewAgent",
        name: "reviewAgent",
        component: () => import('@/views/agentScreen/AgentScreen.vue'), // Lazy loaded
        props: () => ({agentType: 'product'}), // Pass query params as props
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/aiChat",
        name: "aiChat",
        component: () => import('@/views/asistant/OpenAiAssistant.vue'), // Lazy loaded
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/documents",
        name: "documents",
        component: () => import('@/views/documents/DocumentUpload.vue'), // Lazy loaded
        beforeEnter: authenticatedGuard,
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import('@/views/AdminPage.vue'), // Lazy loaded
        beforeEnter: adminGuard,
    },
    {
        path: "/user",
        name: "userPage",
        component: () => import('@/views/UserPage.vue'), // Lazy loaded
        beforeEnter: userGuard,
    },
    {
        path: "/agentDefinition",
        name: "AgentDefinitionsPage",
        component: () => import('@/views/definitions/AgentDefinition.vue'),
        beforeEnter: adminGuard,
    },
    {
        path: "/404",
        name: "not-found",
        component: () => import('@/views/NotFoundView.vue'), // Lazy loaded
    },
    {
        path: "/:agentType/conversation/:conversationId",
        name: "ConversationMessages",
        component: () => import('@/views/conversations/ConversationScreen.vue'), // Lazy loaded
        props: true,
        beforeEnter: authenticatedGuard,
    },
    // catch all 404
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

export default routes;
